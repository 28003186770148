<template>
  <div>
    <v-card v-if="step == 0">
      <v-toolbar flat dense>
        <v-toolbar-title>
          {{ formatType() }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close'), (item = {}), (removed=[]), (step = 0), selectedContainers = []">X</v-btn>
      </v-toolbar>
      <v-card-text :key="refreshKey" style="max-height: 75vh; overflow-y: auto">
        <!-- <v-row>
          <v-col cols="12" sm="6" class="my-0 py-0"> -->
            <v-autocomplete style="max-width: 400px"
              dense
              outlined
              :items="availableParties"
              label="Responsible Party*"
              item-text="name"
              item-value="value"
              v-model="item.responsibleOrganisationId"
            >
            <template v-slot:item="data">
              <v-list-item-action>
                <v-avatar size="32" :color="data.item.logo?'white':'secondary'">
                <v-img v-if="data.item.logo" :src="data.item.logo" contain></v-img>
                <h3 v-else>{{ data.item.name.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.item.role }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </template>
          </v-autocomplete>
            <v-textarea
          class="mb-0"
          v-model="item.reason"
          label="Notes*"
          outlined
          clearable
        ></v-textarea>
          <!-- </v-col>
        </v-row> -->
        <v-row class="mt-3 mb-3 mx-2" justify="end">
          <v-btn small rounded outlined @click="step = 1" :disabled="!item.responsibleOrganisationId || !item.reason" color="primary">Next <v-icon small right>chevron_right</v-icon></v-btn>
        </v-row>
        </v-card-text>
        </v-card>
    <v-card v-else-if="step == 1">
      <v-toolbar flat dense>
        <v-toolbar-title>
          {{ formatType() }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close'), (item = {}), (removed=[]), (step = 0), selectedContainers = []">X</v-btn>
      </v-toolbar>   
      <v-card-text :key="refreshKey" style="max-height: 75vh; overflow-y: auto">
        <div v-if="type == 'CHANGE_SHIPPING_LINE'">
        <v-card-text>
          <v-text-field dense outlined rounded prepend-inner-icon="search" autofocus placeholder="Search" clearable
            v-model="searchShippingLine"></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-for="carrier in filterShippingLines" :key="carrier.id" @click="setShippingLine(carrier),step = 2">
              <v-list-item-action>
                <v-avatar v-if="carrier.logo" color="white">
                  <v-img :src="carrier.logo" contain></v-img>
                </v-avatar>
                <v-avatar v-else color="secondary">
                  <h3>{{ carrier.name ? carrier.name.charAt(0) : '' }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ carrier.name }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  carrier.friendlyName
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
</div>
        <div v-if="type == 'CHANGE_DESTINATION'">
          <v-row>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-list dense class="my-0 py-0">
                <v-subheader style="font-size: 14px"
                  >Port of Discharge</v-subheader
                >
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-action>
                    <v-avatar size="24">
                      <v-img
                        contain
                        :src="`https://cdn.loglive.io/flags/4x3/${shipment.portOfDischargeValue
                          .substring(0, 2)
                          .toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ shipment.portOfDischargeCity }} ({{
                        shipment.portOfDischargeValue
                      }})
                    </v-list-item-title>
                    <v-list-item-subtitle> Current Value </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <div style="width: 100%" class="text-center">
                  <v-icon>arrow_downward</v-icon>
                </div>

                <v-list-item
                  v-if="!item.portOfDischargeValue"
                  @click="searchLocation('portOfDischarge')"
                >
                  <v-list-item-action>
                    <v-icon color="blue">add_circle_outline</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title style="color: grey; font-weight: bold">
                      Set New POD
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-else>
                  <v-list-item-action>
                    <v-avatar size="24">
                      <v-img
                        contain
                        :src="`https://cdn.loglive.io/flags/4x3/${item.portOfDischargeValue
                          .substring(0, 2)
                          .toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.portOfDischargeCity }} ({{
                        item.portOfDischargeValue
                      }})
                    </v-list-item-title>
                    <v-list-item-subtitle> New Value </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row justify="center">
                      <v-btn
                        small
                        icon
                        @click="searchLocation('portOfDischarge')"
                        ><v-icon small>refresh</v-icon></v-btn
                      >
                      <v-btn
                        small
                        icon
                        color="red"
                        @click="removeLocation('portOfDischarge')"
                        ><v-icon small>cancel</v-icon></v-btn
                      >
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-list dense class="my-0 py-0">
                <v-subheader style="font-size: 14px"
                  >Final Destination</v-subheader
                >
                <v-divider></v-divider>
                <v-list-item v-if="shipment.finalDestinationValue">
                  <v-list-item-action>
                    <v-avatar size="24">
                      <v-img
                        contain
                        :src="`https://cdn.loglive.io/flags/4x3/${shipment.finalDestinationValue
                          .substring(0, 2)
                          .toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ shipment.finalDestinationCity }} ({{
                        shipment.finalDestinationValue
                      }})
                    </v-list-item-title>
                    <v-list-item-subtitle> Current Value </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <div style="width: 100%" class="text-center">
                  <v-icon>arrow_downward</v-icon>
                </div>

                <v-list-item
                  v-if="!item.finalDestinationValue"
                  @click="searchLocation('finalDestination')"
                >
                  <v-list-item-action>
                    <v-icon color="blue">add_circle_outline</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title style="color: grey; font-weight: bold">
                      Set New Destination
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-else>
                  <v-list-item-action>
                    <v-avatar size="24">
                      <v-img
                        contain
                        :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                          .substring(0, 2)
                          .toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.finalDestinationCity }} ({{
                        item.finalDestinationValue
                      }})
                    </v-list-item-title>
                    <v-list-item-subtitle> New Value </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row justify="center">
                      <v-btn
                        small
                        icon
                        @click="searchLocation('finalDestination')"
                        ><v-icon small>refresh</v-icon></v-btn
                      >
                      <v-btn
                        small
                        icon
                        color="red"
                        @click="removeLocation('finalDestination')"
                        ><v-icon small>cancel</v-icon></v-btn
                      >
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </div>
        <div v-else-if="type == 'CHANGE_PROFILE'">
        <ChangeShipmentProfile :customerId="customerId" @processShipmentProfile="setShipmentProfile" :refreshKey="profileKey"/>
        </div>
        <div v-else-if="type == 'SHORT_SHIPMENT'">
          <p>Select the affected container(s):</p>
          <v-list dense class="my-0 py-0">
            <v-list-item v-for="container in containers" :key="container.id" style="height: 30px">
            <v-list-item-action>
              <v-checkbox v-model="selectedContainers" :value="container.id"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ container.containerNo }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ container.ctoNo }}
              </v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <div v-else-if="type == 'MOVE_CONTAINER'">
          <v-stepper
    v-model="containerStep"
    vertical
  >
    <v-stepper-step
      :complete="containerStep > 1"
      :editable="containerStep > 1"
      step="1"
    >
      Manage File <br v-if="item.shipmentFile"/>
      <small v-if="item.shipmentFile">{{ item.shipmentFile.fileNumber }}</small>
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-card
      >
    <v-card-text>
      <p>What would you like to do?</p>
          <v-list dense class="my-0 py-0">
            <v-row>
              <v-col cols="12" sm="6">
                <v-list-item @click="selectExistingFile=true">
                  <v-list-item-action>
                    <v-icon color="orange">merge</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Move to Existing File</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item @click="newFileModal = true">
                  <v-list-item-action>
                    <v-icon color="teal">post_add</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Create New File</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            </v-list>
    </v-card-text>
    </v-card>
    </v-stepper-content>
    <v-stepper-step
      :complete="containerStep >=2"
      :editable="containerStep > 2"
      step="2"
    >
      Select Containers
      <small>{{ selectedContainers.length }} Selected</small>
    </v-stepper-step>

    <v-stepper-content step="2">
      <v-card
      >
    <v-card-text>
      <p>Select which containers you'd like to move:</p>
          <v-list dense class="my-0 py-0">
            <v-list-item v-for="container in containers" :key="container.id" style="height: 30px">
            <v-list-item-action>
              <v-checkbox v-model="selectedContainers" :value="container.id"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ container.containerNo }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ container.ctoNo }}
              </v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
          </v-list>
    </v-card-text>
    </v-card>
    </v-stepper-content>
    </v-stepper>
    

      
        
        </div>
        <div v-else-if="type == 'CHANGE_PARTY'">
          <v-list dense :key="partyRefresh">
            <v-row v-for="(party, i) in allPartyTypes" :key="i" align="center">
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-list-item >
                  <v-list-item-action class="mx-0 px-0 mr-2">
                    <v-avatar
                      size="32"
                      :color="
                        shipment[party] && shipment[party].logo
                          ? 'white'
                          : shipment[party] ? 'secondary': 'grey'
                      "
                    >
                      <v-img
                        contain
                        v-if="shipment[party] && shipment[party].logo"
                        :src="shipment[party].logo"
                      ></v-img>
                      <h4 v-else-if="shipment[party]">
                        {{ shipment[party].name.charAt(0) }}
                      </h4>
                      <v-icon v-else small>help</v-icon>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-if="shipment[party]">
                        {{ shipment[party].name }}</span
                      >
                      <span v-else>-</span>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="shipment[party]"
                      style="font-size: 10px; cursor: pointer; color: grey"
                      class="text-wrap"
                      
                    >
                        <v-icon x-small color="grey" left
                        >location_on</v-icon
                      >
                        <span v-if="shipment[party+'Address']">{{
                        concatenateAddress(shipment[party+'Address'])
                      }}</span
                      ><span v-else>No Address</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 12px">
                      {{ formatPartyType(party) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <!-- <div style="width: 100%" class="text-center"> -->
                    <v-icon>arrow_forward</v-icon>
                    <!-- </div> -->
                  </v-list-item-action>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0 ml-0 pl-0">
                <v-list-item v-if="item[party]" class="ml-0 pl-0">
                  <v-list-item-action class="mx-0 px-0 mr-2">
                    <v-avatar
                      size="32"
                      :color="
                        item[party] && item[party].logo
                          ? 'white'
                          : item[party]
                          ? 'secondary'
                          : 'transparent'
                      "
                    >
                      <v-img
                        contain
                        v-if="item[party] && item[party].logo"
                        :src="item[party].logo"
                      ></v-img>
                      <h4 v-else-if="item[party]" style="color: white">
                        {{ item[party].name.charAt(0) }}
                      </h4>
                      <v-icon color="primary" v-else>add_circle_outline</v-icon>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      @click="searchParty(party)"
                      style="cursor: pointer"
                    >
                      {{ item[party].name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="item[party]"
                      style="font-size: 10px; cursor: pointer"
                      class="text-wrap"
                      
                    >
                        <v-icon x-small color="grey" left
                        >location_on</v-icon
                      >
                        <span v-if="item[party+'Address']">{{
                        item[party+'Address'].description
                      }}</span
                      ><span v-else>No Address</span> <v-btn icon small class="ml-1" @click="editAddress(party)"><v-icon small>edit</v-icon></v-btn> 
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row justify="center">
                      <v-btn small icon @click="revertChange(party)"><v-icon>undo</v-icon></v-btn>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item @click="removeParty(party)"
                  v-else-if="removed.map(x=>x.type).includes(party)"
                  class="ml-0 pl-0"
                >
                <v-list-item-action>
                  <v-icon color="secondary">undo</v-icon>
                </v-list-item-action>
                  <v-list-item-content>
                   <v-list-item-title>Reinstate Party</v-list-item-title>
                   </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-else
                  class="ml-0 pl-0"
                >
                  <v-list-item-content>
                    <v-container>
                      <v-row align="center" justify="start">
                        <v-btn v-if="shipment[party]" small rounded text color="blue" style="text-transform: none" @click="searchParty(party)">
                          <v-icon small left>refresh</v-icon> Change
                        </v-btn>
                        <v-btn v-else small rounded text color="primary" style="text-transform: none" @click="searchParty(party)">
                          <v-icon small left>add_circle_outline</v-icon> Add
                        </v-btn>

                        <v-btn  small rounded text color="red" v-if="shipment[party]" style="text-transform: none" @click="removeParty(party, shipment[party+'Id'])">
                          <v-icon small left>cancel</v-icon> Remove
                        </v-btn>

                        <v-btn small rounded text color="teal" v-if="shipment[party+'Address']" style="text-transform: none" @click="editExistingAddress(party)">
                          <v-icon small left>edit_location</v-icon> Edit Address
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-divider></v-divider>
            </v-row>

          </v-list>
        </div>
        <div v-else-if="type == 'CHANGE_VESSEL'">
          <v-list dense>
            <v-row v-if="item.vesselId">
              <v-col cols="12" sm="6">
                <v-list-item @click="sailingScheduleModal = true">
                  <v-list-item-action v-if="!item.shippingLine">
                    <v-icon color="secondary">directions_boat</v-icon>
                  </v-list-item-action>
                  <v-list-item-avatar v-else>
                    <v-avatar color="white" size="36" v-if="item.shippingLine">
                      <v-img
                        v-if="item.shippingLine && item.shippingLine.logo"
                        :src="item.shippingLine.logo"
                        contain
                      ></v-img>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-if="item.shippingLine">{{
                      item.shippingLine.name
                    }}</v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle>Shipping Line</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!-- <v-autocomplete v-model="item.vesselId" outlined dense label="Vessel*"  :loading="loadingVessels"
                                :search-input.sync="vesselSearch" hide-no-data :menu-props="{ closeOnContentClick: true }"
                                :items="vessels" item-text="name" item-value="id"></v-autocomplete> -->
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item @click="sailingScheduleModal = true">
                  <v-list-item-action v-if="!item.shippingLine">
                    <v-icon color="secondary">directions_boat</v-icon>
                  </v-list-item-action>
                  <v-list-item-avatar v-else>
                    <v-avatar color="white" size="36" v-if="item.shippingLine">
                      <v-img
                        v-if="item.shippingLine && item.shippingLine.logo"
                        :src="item.shippingLine.logo"
                        contain
                      ></v-img>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content v-if="item.vessel">
                    <v-list-item-title>
                      {{ item.vessel.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <u style="cursor: pointer" v-if="item.voyage"
                        >Voyage No: {{ item.voyage }}</u
                      >
                      <u style="cursor: pointer" v-else>No Voyage No</u>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>Vessel/Voyage</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content v-else>
                    <span style="font-size: 16px">Add Vessel</span>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-list-item v-else @click="sailingScheduleModal = true">
              <v-list-item-action>
                <v-icon color="secondary">search</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <span style="font-size: 16px">Search Vessel</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      
        
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-between" class="mt-1 mb-2 mx-2">
          <v-btn
            @click="step = 0"
            color="primary"
            small rounded outlined
            ><v-icon small left>chevron_left</v-icon> Back</v-btn
          >
          <v-btn
            @click="step = 2"
            color="primary"
            small rounded outlined
            v-if="type == 'CHANGE_DESTINATION'"
            :disabled="
              (!item.portOfDischargeValue && !item.finalDestinationValue) ||
              !item.reason ||
              !item.responsibleOrganisationId
            "
            >Next <v-icon small right>chevron_right</v-icon></v-btn
          >
          <!-- <v-btn
            @click="step = 2"
            color="primary"
            small rounded outlined
            v-if="type == 'CHANGE_SHIPPING_LINE'"
            :disabled="
              !item.reason ||
              !item.responsibleOrganisationId
            "
            >Next<v-icon small right>chevron_right</v-icon></v-btn
          > -->
          <v-btn
            @click="step = 2"
            color="primary"
            small rounded outlined
            v-else-if="type == 'MOVE_CONTAINER'"
            :disabled="
            !item.shipmentFileId ||
              (selectedContainers.length == 0) ||
              !item.reason ||
              !item.responsibleOrganisationId
            "
            >Next <v-icon small right>chevron_right</v-icon></v-btn
          >
          <v-btn
            @click="step = 2"
            color="primary"
            small rounded outlined
            v-else-if="type == 'SHORT_SHIPMENT'"
            :disabled="
              (selectedContainers.length == 0) ||
              !item.reason ||
              !item.responsibleOrganisationId
            "
            >Next <v-icon small right>chevron_right</v-icon></v-btn
          >
          <v-btn
            @click="step = 2"
            color="primary"
            small rounded outlined
            v-else-if="type == 'CHANGE_CONSIGNEE'"
            :disabled="
              !item.consigneeId ||
              !item.consigneeAddressId ||
              !item.reason ||
              !item.responsibleOrganisationId
            "
            >Next<v-icon small right>chevron_right</v-icon></v-btn
          >
          <v-btn
            @click="step = 2"
            color="primary"
            small rounded outlined
            v-else-if="type == 'CHANGE_VESSEL'"
            :disabled="
              !item.vesselId ||
              !item.voyage ||
              !item.reason ||
              !item.responsibleOrganisationId
            "
            >Next<v-icon small right>chevron_right</v-icon></v-btn
          >
          <v-btn
            @click="step = 2"
            color="primary"
            small rounded outlined
            v-else-if="type == 'CHANGE_PARTY'"
            :disabled="
              !item.reason ||
              !item.responsibleOrganisationId
            "
            >Next<v-icon small right>chevron_right</v-icon></v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>

    <v-card v-else>
      <v-toolbar flat dense>
        <v-toolbar-title>
          {{ formatType() }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
       
        <v-btn text @click="close()">X</v-btn>
      </v-toolbar>
      <v-card-text>
        <v-subheader style="font-size: 14px">
          <v-chip class="mr-1">{{ selectedDocuments.length }}</v-chip> Affected
          Documents</v-subheader
        >
        <v-divider></v-divider>
        <v-list dense style="max-height: 60vh; overflow-y: auto">
          <v-list-item v-for="document in documents" :key="document.id">
            <v-list-item-action>
              <v-checkbox
                v-model="selectedDocuments"
                :value="document.id"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ document.locationDocument.name }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="document.bookingContainer">
                <span v-if="document.bookingContainer.containerNo">{{
                  document.bookingContainer.containerNo
                }}</span>
                <span v-else>{{ document.bookingContainer.ctoNo }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip top v-if="document.quantityType == 'Shipment'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    icon
                    outlined
                    small
                    class="mx-1"
                    v-on="on"
                  >
                    <v-icon small>directions_boat</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 12px; text-transform: capitalize">{{
                  document.quantityType
                }}</span>
              </v-tooltip>

              <v-tooltip top v-else-if="document.quantityType == 'Container'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    icon
                    outlined
                    small
                    class="mx-1"
                    v-on="on"
                    color="orange"
                  >
                    <v-icon small>widgets</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 12px; text-transform: capitalize">{{
                  document.quantityType
                }}</span>
              </v-tooltip>

              <v-tooltip
                top
                v-else-if="document.quantityType == 'CountContainer'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    icon
                    outlined
                    small
                    class="mx-1"
                    v-on="on"
                    color="blue"
                  >
                    <v-icon small>view_module</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 12px; text-transform: capitalize">
                  Per {{ document.count }} Containers</span
                >
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-between" class="mt-1 mb-2 mx-2">
          <v-btn
            @click="step = 1"
            color="primary"
            small rounded outlined
            ><v-icon small left>chevron_left</v-icon> Back</v-btn
          >
          <v-btn
          @click="submitChange()"
          :loading="submitting"
          color="blue"
          small rounded outlined
          >Submit</v-btn
        >
        </v-row>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="searchModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            <span v-if="searchType == 'portOfDischarge'"
              >Port of Discharge</span
            >
            <span v-else>Final Destination</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="my-0 py-0"
            autofocus
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="portSearch"
            hide-details
          ></v-text-field>
          <v-progress-linear
            v-if="loadingPortSearch"
            color="primary"
            indeterminate
          ></v-progress-linear>
          <v-expand-transition>
            <v-card flat class="my-0 py-0">
              <v-card-text class="my-0 py-0">
                <v-virtual-scroll
                  v-if="portResults.length > 0"
                  class="my-0 py-0"
                  :bench="0"
                  :items="portResults"
                  height="400"
                  item-height="50"
                >
                  <template v-slot:default="{ item }">
                    <v-list-item :key="item.id" @click="setPort(item)">
                      <v-list-item-avatar>
                        <v-avatar size="24" class="mr-1" left>
                          <v-img
                            contain
                            v-if="item.locode"
                            :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 14px">
                          {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          style="font-size: 12px; color: grey"
                        >
                          {{ item.locode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
                <v-list-item v-else>
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 12px"
                      >No results found</span
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="searchPartyModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title> Find {{ partyType }} </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="my-0 py-0"
            autofocus
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="organisationSearch"
            hide-details
          ></v-text-field>
          <v-progress-linear
            v-if="loadingLinkedOrganisations"
            color="primary"
            indeterminate
          ></v-progress-linear>

          <v-virtual-scroll
            v-if="linkedOrganisations.length > 0"
            class="my-0 py-0"
            :bench="0"
            :items="filterLinkedOrganisations"
            height="400"
            item-height="50"
          >
            <template v-slot:default="{ item }">
              <v-list-item :key="item.id" @click="setParty(item)">
                <v-list-item-action>
                  <v-avatar
                    size="32"
                    :color="
                      item.relatedOrganisation.logo ? 'white' : 'secondary'
                    "
                  >
                    <v-img
                      contain
                      v-if="item.relatedOrganisation.logo"
                      :src="item.relatedOrganisation.logo"
                    ></v-img>
                    <h4 v-else>
                      {{ item.relatedOrganisation.name.charAt(0) }}
                    </h4>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">
                    {{ item.relatedOrganisation.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
          <v-list-item v-else>
            <v-list-item-content class="text-center">
              <span style="color: grey; font-size: 12px">No results found</span>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sailingScheduleModal"
      persistent
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">directions_boat</v-icon>
            Search Sailing Schedules
          </v-toolbar-title>
          <v-chip
            v-if="shipment.portOfLoadValue"
            small
            outlined
            style="border: none"
            class="ml-2"
          >
            <v-avatar size="24" left>
              <v-img
                contain
                v-if="shipment.portOfLoadValue"
                :src="`https://cdn.loglive.io/flags/4x3/${shipment.portOfLoadValue
                  .substring(0, 2)
                  .toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
            {{ shipment.portOfLoadValue }}
          </v-chip>

          <v-icon class="mx-3" color="grey" small>east</v-icon>
          <v-chip
            v-if="shipment.portOfDischargeValue"
            small
            outlined
            style="border: none"
          >
            <v-avatar size="24" left>
              <v-img
                contain
                v-if="shipment.portOfDischargeValue"
                :src="`https://cdn.loglive.io/flags/4x3/${shipment.portOfDischargeValue
                  .substring(0, 2)
                  .toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
            {{ shipment.portOfDischargeValue }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn text @click="sailingScheduleModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <ScheduleModal
            :export="shipment.movementType == 'EXPORT'"
            :etd="shipment.etd"
            :portOfLoadValue="shipment.portOfLoadValue"
            :finalDestinationValue="shipment.portOfDischargeValue"
            :shippingLines="shippingLines"
            @select="selectSchedule"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="organisationAddressModal" width="400px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title> Select Address </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="organisationAddressModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense>
            <v-list-item v-if="organisationAddresses.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px"
                  >No addresses found</span
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="address in organisationAddresses"
              :key="address.id"
              @click="setAddress(address)"
            >
              <v-list-item-action>
                <v-icon color="grey">location_on</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  style="font-size: 11px; color: grey"
                  class="text-wrap"
                >
                  {{ address.description }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="existingFileModal" persistent width="750px">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title> Select File </v-toolbar-title>
      </v-toolbar>
    </v-card>
    </v-dialog>

    <v-dialog v-model="selectExistingFile" persistent width="90vw" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card flat>
        <v-toolbar flat>
          <v-toolbar-title>Select File</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="selectExistingFile=false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-card flat>
            <v-row class="pa-3" align="center" justify="end">
                <v-chip color="red" class="mx-1" small @click="resetFilters()">
                    <v-icon small left>filter_alt_off</v-icon> Clear
                    Filters
                </v-chip>

                <v-chip v-if="!loading" color="blue-grey" class="mx-1" small>
                    <v-icon small left>widgets</v-icon> {{ displayData.length }} Files
                </v-chip>
                <v-col cols="12" sm="4">
                    <v-text-field hide-details prepend-inner-icon="search" placeholder="Search" v-model="search" outlined dense
                    rounded clearable></v-text-field>
                </v-col>
               
            </v-row>

            <v-card-text>
                <v-row class="mx-1">
                 
                    <v-col cols="12" sm="2" class="text-center">
                        <b>File No.</b>

                    </v-col>
                    <v-col cols="12" sm="1">
                        <th class="text-center">Carrier</th>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <b>Vessel</b>
                    </v-col>
                    <v-col cols="12" sm="1">
                        <b>POL></b>
                    </v-col>
                    <v-col cols="12" sm="1">
                        <b>Dest.</b>
                    </v-col>
                    <v-col cols="12" sm="1">
                        <th class="text-center">Shipper</th>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <th class="text-center">Consignee
                           
                        </th>
                    </v-col>
                    <v-col cols="12" sm="1" class="text-center">
                        <b>ETD</b>
                       
                    </v-col>

                    <v-col cols="12" sm="1" class="text-center">
                        <b>ETA</b>
          
                    </v-col>

              
                </v-row>
                <v-divider></v-divider>
                <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
                <div class="mt-1 pt-1">
                    <div class="container" v-if="!loading && displayData.length == 0">
                        <h3 style="color: grey">No Results Found</h3>
                    </div>
                    <v-virtual-scroll v-if="displayData.length > 0" :bench="10" :items="displayData" height="720px"
                        item-height="30">
                        <template v-slot:default="{ item }">
                            <v-row align="center" style="cursor: pointer"  outlined @click="selectFile(item)">
                                
                                <v-col cols="12" sm="2" class="text-center">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-chip small style="border:none; font-size: 12px" v-bind="attrs" v-on="on">
                                        <!-- <v-avatar size="20" left>
                                        {{ item.type.charAt(0).toUpperCase() }}
                                        </v-avatar> -->
                                        <v-icon left v-if="item.status == 'Draft'" small color="orange">edit_note</v-icon>
                                        <v-icon left v-else-if="item.status == 'Ready'" small color="blue">verified</v-icon>
                                        {{ item.fileNumber }}
                                    </v-chip>
                                    </template>
                                    <span>{{ 'TYPE: ' + item.type }}</span>
                                </v-tooltip>
                                </v-col>
                  
                                <v-col cols="12" sm="1">
                                    <v-chip v-if="item.shippingLine" small class="primaryText--text" outlined
                                        style="border: none; font-size: 11px">
                                        <v-avatar size="24"
                                            :color="item.shippingLine && item.shippingLine.logo ? 'white' : 'secondary'"
                                            left>
                                            <v-img v-if="item.shippingLine.logo"
                                                :src="item.shippingLine.logo" contain></v-img>
                                            <h3 v-else style="color: white">{{ item.shippingLineName.charAt(0) }}</h3>
                                        </v-avatar> {{ item.shippingLineName }}
                                    </v-chip>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <v-chip v-if="item.vesselVoyage" small class="primaryText--text" outlined style="border: none; font-size: 11px">
                                        {{ item.vesselVoyage }} 
                                    </v-chip>
                                </v-col>
                                <v-col cols="12" sm="1">
                                    <v-tooltip top v-if="item.portOfLoadValue">
                                        <template v-slot:activator="{ on }">
                                            <v-chip outlined style="border: none; font-size: 11px" v-on="on" small
                                                @click="addToFilter(item.portOfLoadValue, 'portOfLoadValue')">
                                                <v-avatar size="20" left>
                                                    <v-img contain
                                                        :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                </v-avatar> {{ item.portOfLoadValue }}   
                                                <!-- <span class="ml-2" style="color: #9E9E9E">{{ formatDate(item.etd)
                                                        }}</span> -->
                                            </v-chip>
                                        </template>
                                        <span style="font-size: 12px">{{ item.portOfLoadCity }}</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="12" sm="1">
                                    <v-tooltip top v-if="item.finalDestinationValue">
                                        <template v-slot:activator="{ on }">
                                            <v-chip outlined style="border: none; font-size: 11px" v-on="on" small>
                                                <v-avatar size="20" left>
                                                    <v-img contain
                                                        :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                </v-avatar> {{ item.finalDestinationValue }}   
                                                <!-- <span class="ml-2" style="color: #9E9E9E">{{ formatDate(item.eta)
                                                        }}</span> -->
                                            </v-chip>
                                        </template>
                                        <span style="font-size: 12px">{{ item.finalDestinationCity }}</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="12" sm="1">
                                    <v-tooltip top v-if="item.shipper">
                                        <template v-slot:activator="{ on }">
                                            <v-chip v-on="on" small class="primaryText--text" outlined style="border: none; font-size: 11px">
                                                <v-avatar size="20"
                                                    :color="item.shipper && item.shipper.logo ? 'white' : 'secondary'"
                                                    left>
                                                    <v-img v-if="item.shipper.logo" :src="item.shipper.logo"
                                                        contain></v-img>
                                                    <h3 v-else style="color: white">{{ item.shipperName.charAt(0) }}</h3>

                                                </v-avatar> {{ item.shipperName }}
                                            </v-chip>
                                        </template>
                                        {{ item.shipper.name }}
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-tooltip top v-if="item.consignee">
                                        <template v-slot:activator="{ on }">
                                            <v-chip small class="primaryText--text" outlined v-on="on"
                                                style="border: none; font-size: 11px">
                                                <v-avatar size="20"
                                                    :color="item.consignee && item.consignee.logo ? 'white' : 'secondary'"
                                                    left>
                                                    <v-img v-if="item.consignee.logo"
                                                        :src="item.consignee.logo" contain></v-img>
                                                    <h3 v-else style="color: white">{{ item.consigneeName.charAt(0) }}</h3>

                                                </v-avatar> {{ item.consigneeName }}
                                            </v-chip>
                                        </template>
                                        {{ item.consignee.name }}
                                    </v-tooltip>

                                </v-col>
                                <v-col cols="12" sm="1" class="text-center">
                                    <v-chip small class="primaryText--text" outlined
                                        style="border: none; font-size: 11px">
                                        {{ formatDate(item.etd) }}
                                    </v-chip>
                                </v-col>
                                <v-col cols="12" sm="1" class="text-center">
                                    <v-chip small class="primaryText--text" outlined
                                        style="border: none; font-size: 11px">
                                        {{ formatDate(item.eta) }}
                                    </v-chip>
                                </v-col>
                 
                            </v-row>
                            <v-divider class="pt-1"></v-divider>
                        </template>
                    </v-virtual-scroll>
                </div>
            </v-card-text>
        </v-card>

        <v-dialog v-model="previewShipmentFile" width="90vw" :fullscreen="$vuetify.breakpoint.mobile">
            <ViewFile :key="previewKey" v-if="previewShipmentFile && selectedFile && selectedFile.id" @Cancelled="cancelFile"
                :fileId="selectedFile.id" @close="previewShipmentFile = false" :allowShipmentProcess="true" @ProcessedFile="updateData"/>
        </v-dialog>
        
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="newFileModal" persistent width="1400px" :fullscreen="$vuetify.breakpoint.mobile">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title> Create New File </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text color="blue" v-if="fileStep == 2" :disabled="!file.incoTermId"
            @click="(confirmFileCreate = true), (newFileModal = false)"><v-icon left small>save</v-icon> Create
            File</v-btn>
        <v-btn text @click="newFileModal=false, file={}">X</v-btn>

      </v-toolbar>
      <v-card-text>
        <div v-if="fileStep == 1">
        <ChangeShipmentProfile :customerId="customerId" @processShipmentProfile="setFileProfile" :refreshKey="profileKey"/>
        </div>
        <div v-else>
          <v-row justify="center">
            <v-col cols="12" sm="6" v-if="file.consigneeProfile">
              <v-list dense>
                <v-subheader style="font-size: 16px"><v-icon color="secondary" class="mr-2">groups</v-icon>
                  Shipment Profile</v-subheader>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="secondary">handshake</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ file.consigneeProfile.systemReference }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip @click="fileStep = 1" small>
                      Change Profile <v-icon right small>refresh</v-icon>
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="file.consigneeProfile.shipper">
                  <v-list-item-action>
                    <v-icon color="secondary">directions_boat</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ file.consigneeProfile.shipper.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Shipper</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="file.consigneeProfile.consignee">
                  <v-list-item-action>
                    <v-icon color="secondary">call_received</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ file.consigneeProfile.consignee.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Consignee</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="file.consigneeProfile.originCountry">
                  <v-list-item-action>
                    <v-avatar size="32">
                      <v-img contain v-if="file.consigneeProfile.originCountry &&
                        file.consigneeProfile.originCountry.iso2
                        "
                        :src="`https://cdn.loglive.io/flags/4x3/${file.consigneeProfile.originCountry.iso2.toLowerCase()}.svg`"></v-img>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ file.consigneeProfile.originCountry.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Origin Country</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="file.consigneeProfile.destinationCountry">
                  <v-list-item-action>
                    <v-avatar size="32">
                      <v-img contain v-if="file.consigneeProfile.destinationCountry &&
                        file.consigneeProfile.destinationCountry.iso2
                        "
                        :src="`https://cdn.loglive.io/flags/4x3/${file.consigneeProfile.destinationCountry.iso2.toLowerCase()}.svg`"></v-img>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ file.consigneeProfile.destinationCountry.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Destination Country</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6" v-if="file && file.consigneeProfile">
              <v-list dense>
                <v-subheader style="font-size: 16px"><v-icon color="secondary" class="mr-2">groups</v-icon> Deal
                  term
                </v-subheader>
                <v-divider></v-divider>
                <v-list-item v-for="term in file.consigneeProfile
                  .consigneeProfileIncoTerms" :key="term.id" @click="setProfileIncoterm(term)" :disabled="term.contractPartyId !== file.contractPartyId">
                  <v-list-item-action>
                    <v-chip>{{ term.incoTerm }}</v-chip>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-subtitle style="text-transform: capitalize">
                      Origin Charge: 
                      {{ term.originTerm }}
                      <v-chip small outlined style="border: none" class="ml-1" v-if="term.originParty">
                        <v-icon small class="mr-1">groups</v-icon>
                         {{ term.originParty.name }}
                      </v-chip>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="text-transform: capitalize">
                      Freight Charge: 
                      {{ term.paymentTerm }} <v-chip small outlined style="border: none" class="ml-1" v-if="term.freightParty">
                        <v-icon small class="mr-1">groups</v-icon>
                         {{ term.freightParty.name }}
                      </v-chip>
                    </v-list-item-subtitle>
                   
                    <v-list-item-subtitle style="text-transform: capitalize">
                      Destination Charge:
                      {{ term.destinationTerm }}
                      <v-chip small outlined style="border: none" class="ml-1" v-if="term.destinationParty">
                        <v-icon small class="mr-1">groups</v-icon>
                         {{ term.destinationParty.name }}
                      </v-chip>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="text-transform: capitalize">
                      Contract Owner:  <v-chip small outlined style="border: none" class="ml-1" v-if="term.contractParty">
                        <v-icon small class="mr-1">article</v-icon>
                         {{ term.contractParty.name }}
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action :key="incoTermKey">
                    <v-icon v-if="file.incoTermId && term.id == file.incoTermId"
                      color="success">check_circle_outline</v-icon>
                      <v-tooltip top  v-else-if="term.contractPartyId !== booking.contractPartyId">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"
                      color="amber">warning</v-icon>
                      </template>
                      <span>Contract Party does not match</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    </v-dialog>

      <!-- Create file Dialog -->
      <v-dialog v-model="confirmFileCreate" width="400px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmFileCreate = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 16px">
            Are you sure you would like to create a file with the selected
            configuration?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn color="red" class="mx-2" text small
              @click="(confirmFileCreate = false), (createFileDialog = true)">No</v-btn>
            <v-btn color="success" class="mx-2" text small @click="createBookingFile()"
              :loading="loadingCreateFile">Yes</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import dateFormat from "dateformat";
import ViewFile from "../SystemComponents/PreviewFileModal.vue";
import ScheduleModal from "../Bookings//Dialogs/SailingSchedule.vue";
import ChangeShipmentProfile from "./ChangeShipmentProfile.vue";

export default {
  props: ["shipment", "type", "relationships", "documents", "containers", "customerId"],
  components: {
    ChangeShipmentProfile,
    ScheduleModal,
    ViewFile
  },
  data: () => ({
    airlines: [],
    allPartyTypes: [
      'shipper',
      'onBehalfShipper',
      'forwarder',
      'onBehalfForwarder',
      'consignee',
      'onBehalfConsignee',
      'buyer',
      'onBehalfBuyer',
      'firstNotify',
      'secondNotify',
      'courierParty',
    ],
    confirmFileCreate: false,
    containerStep: 1,
    existingFileModal: false,
    file: {},
    files: [],
    fileQuery: undefined,
    fileTimeout: undefined,
    filteredResults: [],
    filters: {},
    fileStep: 1,
    headers: [
            {
                text: 'Status',
                value: 'status'
            },
            {
                text: 'Vessel',
                value: 'vesselVoyage'
            },
            {
                text: 'Shipper',
                value: 'shipperName'
            },
            {
                text: 'Consignee',
                value: 'consigneeName'
            },
            {
                text: 'Carrier',
                value: 'shippingLineName'
            }, {
                text: 'POL',
                value: 'portOfLoadValue'
            }, {
                text: 'Destination',
                value: 'finalDestinationValue'
            }, {
                text: 'ETD',
                value: 'etd'
            }, {
                text: 'ETA',
                value: 'eta'
            }
        ],
        incoTermKey: 800,
    item: {},
    linkedOrganisations: [],
    loading: false,
    shippingLineModal: false,
    searchShippingLine: null,
    shippingLines: [],
    loadingAddresses: false,
    loadingCreateFile: false,
    loadingLinkedOrganisations: false,
    loadingPortSearch: false,
    loadingVessels: false,
    newFileModal: false,
    organisationAddressModal: false,
    organisationAddresses: [],
    organisationSearch: null,
    partyRefresh: 200,
    portSearchTimeout: undefined,
    portSearchQuery: undefined,
    portSearch: null,
    portResults: [],
    previewShipmentFile: false,
    previewKey: 700,
    profileKey: 600,
    refreshKey: 100,
    removed: [],
    sailingScheduleModal: false,
    selectedContainers: [],
    search: null,
    searchType: null,
    searchPartyModal: false,
    searchPartyType: null,
    searchModal: false,
    selectExistingFile: false,
    selectedFile: {},
    selectedDocuments: [],
    submitting: false,
    step: 0,
    vesselSearch: null,
    vessels: [],
    vesselQuery: undefined,
    vesselTimeout: undefined,
  }),
  watch: {
    type(val) {
      if (val == "CHANGE_VESSEL") {
        this.sailingScheduleModal = true;
      }
      this.profileKey ++;
    },
    async portSearch(val) {
      if (this.loadingPortSearch) {
        clearTimeout(this.portSearchTimeout);
      }
      if (this.loadingPortSearch && this.portSearchQuery) {
        this.portSearchQuery.abort();
      }
      this.loadingPortSearch = true;
      let params = {
        search: val,
        // portType: "port",
      };
      this.portSearchTimeout = setTimeout(async () => {
        this.portSearchQuery = new AbortController();
        const signal = this.portSearchQuery.signal;
        this.portResults = await this.$API.searchTowns({
          params: params,
          signal,
        });
        this.loadingPortSearch = false;
      }, 750);
    },
    async vesselSearch(val) {
      if (this.loadingPortSearch) {
        clearTimeout(this.vesselTimeout);
      }
      if (this.loadingVessels && this.vesselQuery) {
        this.vesselQuery.abort();
      }
      this.loadingVessels = true;
      let params = {
        search: val,
      };
      this.vesselTimeout = setTimeout(async () => {
        this.vesselQuery = new AbortController();
        const signal = this.vesselQuery.signal;
        this.vessels = await this.$API.searchTowns({
          params: params,
          signal,
        });
        this.loadingVessels = false;
      }, 750);
    },
  },
  computed: {
    availableParties() {
      let result = [];
      let parties = [
        "shipper",
        "onBehalfShipper",
        "forwarder",
        "onBehalfForwarder",
        "consignee",
        "onBehalfConsignee",
        "buyer",
        "onBehalfBuyer",
        "firstNotify",
        "secondNotify",
        "courierParty",
        "shippingLine"
      ];
      for (let i = 0; i < parties.length; i++) {
        if (this.shipment[parties[i]]) {
          let findExisting = result.find(
            (x) => x.value == this.shipment[parties[i] + "Id"]
          );

          if (!findExisting) {
            result.push({
              logo: this.shipment[parties[i]].logo,
              name: this.shipment[parties[i]].name,
              value: this.shipment[parties[i] + "Id"],
              role: this.formatPartyType(parties[i]),
            });
          }
        }
      }
      if (this.relationships && this.relationships.length > 0) {
        for (let i = 0; i < this.relationships.length; i++) {
          let findExisting = result.find(
            (x) => x.value == this.relationships[i].customerId
          );
          if (!findExisting) {
            result.push({
              name: this.relationships[i].customer.name,
              value: this.relationships[i].customerId,
            });
          }
        }
      }
      // objs.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      // result.push({ name: 'Other', value: null })
      return result;
    },
    filterShippingLines() {
      let result = this.shipment.movementType == 'OCEAN' ? this.shippingLines : this.airlines;    
      if (this.searchShippingLine) {
        result = result.filter(
          (x) =>
            (x.name &&
              x.name
                .toLowerCase()
                .includes(this.searchShippingLine.toLowerCase())) ||
            (x.friendlyName &&
              x.friendlyName
                .toLowerCase()
                .includes(this.searchShippingLine.toLowerCase()))
        );
      }

      return result;
    },
    displayData() {
            let result = this.filteredResults
            if (this.search) {
                result = result.filter(x =>
                    (x.shipperName && x.shipperName.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.fileNumber && x.fileNumber.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.portOfLoadValue && x.portOfLoadValue.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.portOfLoadCity && x.portOfLoadCity.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.finalDestinationValue && x.finalDestinationValue.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.finalDestinationCity && x.finalDestinationCity.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.shippingLineName && x.shippingLineName.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.vesselVoyage && x.vesselVoyage.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.consigneeName && x.consigneeName.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.eta && x.eta.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.etd && x.etd.toLowerCase().includes(this.search.toLowerCase())))
            }
            return result
        },
    filterLinkedOrganisations() {
      let result = this.linkedOrganisations ?? [];
      if (this.organisationSearch) {
        result = result.filter(
          (x) =>
            x.relatedOrganisation &&
            x.relatedOrganisation.name
              .toLowerCase()
              .includes(this.organisationSearch.toLowerCase())
        );
      }
      return result;
    },
    partyType() {
      switch (this.searchPartyType) {
        case "shipper":
          return "Shipper";
        case "onBehalfShipper":
          return "On Behalf Shipper";
        case "forwarder":
          return "Forwarder";
        case "onBehalfForwarder":
          return "On Behalf Forwarder";
        case "consignee":
          return "Consignee";
        case "onBehalfConsignee":
          return "On Behalf Consignee";
        case "buyer":
          return "Buyer";
        case "onBehalfBuyer":
          return "On Behalf Buyer";
        case "firstNotify":
          return "First Notify";
        case "secondNotify":
          return "Second Notify";
        case "courierParty":
          return "Courier Party";
        default:
          return "Organisation";
      }
    },
  },
    mounted() {
        this.resetFilters()
        this.getFiles()
    },
  async created() {
    this.getRelatedOrganisations();
    this.shippingLines = await this.$API.getShippingLines();
    this.airlines = await this.$API.getAirLines();
  },
  methods: {
    close(){
      this.$emit('close')
      this.item = {}
      this.removed=[]
      this.step = 0
      this.selectedContainers = []
      this.fileStep = 0
    },
    concatenateAddress(address) {
      if (address) {
        let result = [
          this.constructAddressLine(1, address),
          this.constructAddressLine(2, address),
          this.constructAddressLine(3, address),
        ];
        return result.filter(Boolean).join(", ");
      } else {
        return;
      }
    },
    constructAddressLine(addressLine, address) {
      switch (addressLine) {
        case 1:
          return [address.addressLine1, address.addressLine2]
            .filter(Boolean)
            .join(", ");
        case 2:
          return [address.addressLine3, address.addressLine4]
            .filter(Boolean)
            .join(", ");
        case 3:
          return [
            address.town,
            address.subdivision,
            address.country,
            address.postalCode,
          ]
            .filter(Boolean)
            .join(", ");
      }
    },
    async createBookingFile() {
      this.loadingCreateFile = true;
      let obj = {
        consigneeProfileId: this.file.consigneeProfileId,
        shipmentId: this.shipment.id,
        // rmsVesselVoyageId: this.booking.rmsVesselVoyaegId,
        incoTermId: this.file.incoTermId,
        term: this.file.term,
        // bookingId: this.booking.id,
        customerId: this.file.customerId,
        containers: []
      };
      let result = await this.$API.createFileFromShipment(obj);
      this.$message.success("File Created Successfully");
      // this.getContainers();
      this.loadingCreateFile = false;
      this.confirmFileCreate = false;
      this.newFileModal = false;
      this.item.shipmentFile = result;
      this.item.shipmentFileId = result.id;
      this.containerStep = 2
    },
    async editExistingAddress(type) {
      this.searchPartyType = type;
      this.item[this.searchPartyType] = this.shipment[type];
      this.item[this.searchPartyType+'Id'] = this.shipment[type+'Id'];
      this.organisationAddresses = await this.$API.listOrganisationAddresses(
        this.shipment[this.searchPartyType + "Id"]
      );
      for (let i = 0; i < this.organisationAddresses.length; i++) {
        this.organisationAddresses[i].description = this.concatenateAddress(
          this.organisationAddresses[i]
        );
      }
      this.organisationAddressModal = true;
    },
    async editAddress(type) {
      this.searchPartyType = type;
      this.organisationAddresses = await this.$API.listOrganisationAddresses(
        this.item[this.searchPartyType + "Id"]
      );
      for (let i = 0; i < this.organisationAddresses.length; i++) {
        this.organisationAddresses[i].description = this.concatenateAddress(
          this.organisationAddresses[i]
        );
      }
      this.organisationAddressModal = true;
    },
    formatPartyType(type){
      switch (type) {
        case "shipper":
          return "Shipper";
        case "onBehalfShipper":
          return "On Behalf Shipper";
        case "forwarder":
          return "Forwarder";
        case "onBehalfForwarder":
          return "On Behalf Forwarder";
        case "consignee":
          return "Consignee";
        case "onBehalfConsignee":
          return "On Behalf Consignee";
        case "buyer":
          return "Buyer";
        case "onBehalfBuyer":
          return "On Behalf Buyer";
        case "firstNotify":
          return "First Notify";
        case "secondNotify":
          return "Second Notify";
          case "shippingLine":
          return "Shipping Line";
        case "courierParty":
          return "Courier Party";
        default:
          return "Organisation";
    }
  },
    formatType() {
      switch (this.type) {
        case "CHANGE_CONSIGNEE":
          return "Change of Consignee";
          case "CHANGE_SHIPPING_LINE":
          return "Change Shipping Line";
        case "CHANGE_DESTINATION":
          return "Change Destination";
        case "CHANGE_VESSEL":
          return "Change Vessel";
          case "CHANGE_PARTY":
          return "Edit Parties"
          case 'MOVE_CONTAINER':
            return 'Move Container(s)'
            case 'SHORT_SHIPMENT':
            return 'Short Shipment'
            case 'CHANGE_PROFILE':
              return 'Change Profile'
      }
    },
    async getRelatedOrganisations() {
      this.loadingLinkedOrganisations = true;
      this.linkedOrganisations =
        await this.$API.getShipmentCustomerRelationships(this.shipment.id);
      // this.customers = await this.$API.getCustomers()
      this.loadingLinkedOrganisations = false;
    },
 
    setShippingLine(item) {
      // this.searchType = type;
      this.item.shippingLine = item;
      this.item.shippingLineId = item.id;

      this.item.carrier = item;
      this.item.carrierId = item.id;

      // this.booking.shippingLine = item;
      // this.booking.shippingLineId = item.id;
      // this.shippingLineModal = false;
      // if (!this.booking.mainCarriageConveyanceNumber) {
      //   this.voyageNumberModal = true;
      // }
      // this.$emit("bookingChange");
    },
    removeLocation(type) {
      this.item[type + "Value"] = null;
      this.item[type + "City"] = null;
      this.refreshKey++;
    },
    removeParty(type, party) {
      let index = this.removed.findIndex((x) => x.type == type);
      if (index == -1) {
        this.removed.push({type, organisationId: party});
      } else {
        this.removed.splice(index, 1);
      }
      console.log(this.removed)
      this.refreshKey++;
    },
    revertChange(party){
     delete this.item[party]
     delete this.item[party+'Id']
      delete this.item[party+'Address']
      delete this.item[party+'AddressId']
      this.partyRefresh ++
    },
    searchLocation(type) {
      this.searchType = type;
      this.searchModal = true;
    },
    selectSchedule(schedule) {
      console.log('new schedule eta', schedule.eta)
      this.item.carrier = schedule.carrier;
      this.item.vessel = schedule.vessel;
      this.item.vesselName = schedule.vessel.name;
      this.item.vesselId = schedule.vesselId;
      this.item.voyage = schedule.voyage;
      this.item.sailingSchedule = schedule;
      this.item.sailingScheduleId = schedule.id;
      this.item.carrierScac = schedule.scac;
      this.item.serviceName = schedule.serviceName;
      this.item.eta = new Date(schedule.eta);
      this.item.etd = new Date(schedule.etd);
      this.item.shippingLine = schedule.shippingLine;
      this.item.shippingLineId = schedule.shippingLineId;
      this.item.carrierName = schedule.carrier;
      this.item.stackDate = schedule.stackDate;
      this.item.stackDateId = schedule.stackDateId;
      this.sailingScheduleModal = false;
    },
    async setParty(item) {
      this.item[this.searchPartyType] = item.relatedOrganisation;
      this.item[this.searchPartyType + "Id"] = item.relatedOrganisation.id;
      this.item[this.searchPartyType + "AddressId"] = null;
      this.item[this.searchPartyType + "Address"] = null;
      this.searchPartyModal = false;
      this.item[this.searchPartyType].loadingAddresses = true;
      this.organisationAddresses = await this.$API.listOrganisationAddresses(
        this.item[this.searchPartyType + "Id"]
      );
      for (let i = 0; i < this.organisationAddresses.length; i++) {
        this.organisationAddresses[i].description = this.concatenateAddress(
          this.organisationAddresses[i]
        );
      }
      this.item[this.searchPartyType].loadingAddresses = false;
      this.organisationAddressModal = true;
    },
    setAddress(address) {
      this.item[this.searchPartyType + "AddressId"] = address.id;
      this.item[this.searchPartyType + "Address"] = address;
      this.organisationAddressModal = false;
    },
    setFileProfile(profile) {
      this.file.customerId = profile.customerId;
      this.file.consigneeProfile = profile.profile;
      this.file.consigneeProfileId = profile.profile.id;
      if (profile.profile.consigneeProfileIncoTerms.length > 0) {
       
          this.setProfileIncoterm(profile.profile.consigneeProfileIncoTerms[0]);
      } else {
        this.file.term = null;
        this.file.incoTermId = null;
      }
      this.fileStep = 2;
    },
    setProfileIncoterm(term) {
      this.file.incoTermId = term.id;
      this.file.term = term.incoTerm;
      this.file.contractPartyId = term.contractPartyId;
      this.incoTermKey++;
    },
    setPort(item) {
      this.item[this.searchType + "Value"] = item.locode;
      this.item[this.searchType + "City"] = item.name;
      this.refreshKey++;
      this.searchModal = false;
    },
    searchParty(type) {
      this.searchPartyType = type;
      this.searchPartyModal = true;
    },
    async submitChange() {
      this.submitting = true;
      this.item.type = this.type;
      this.item.originalPortOfDischargeValue =
        this.shipment.portOfDischargeValue;
      this.item.originalFinalDestinationValue =
        this.shipment.finalDestinationValue;
      this.item.documents = this.selectedDocuments;
      this.item.removed = this.removed;
      this.item.selectedContainers = this.selectedContainers;
      // this.item.shipmentFileId = this.shipment.shipmentFileId
  //  this.item.shippingLineId = this.shipment.shippingLineId
      this.item.shipmentId = this.shipment.id
      let result = await this.$API.createShipmentChange(
        this.shipment.id,
        this.item
      );
      this.$message.success("Successfully created shipment change!");
      this.$emit("refresh");
      this.item = {};
      this.removed = []
      this.selectedContainers = [];
      this.submitting = false;
    },
    setShipmentProfile(item){
      this.item = {...this.item, ...item}
      this.step ++
    },
    
    // Shipment files
    addToFilter(value, field) {
            if (this.filters[field].includes(value)) {
                this.filters[field] = this.filters[field].filter(x => x != value)
            } else {
                this.filters[field].push(value)
            }
            this.calculateFilteredResults()
        },
        calculateFilteredResults() {
            let result = [...this.files]
            let keys = Object.keys(this.filters)
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i]
                if (this.filters[key] && this.filters[key].length > 0) {
                        result = result.filter(x => this.filters[key].includes(x[key]))
                    }
            }
            this.filteredResults = result
        },
        formatDate(date) {
            let result = null;
            if (date) {
                result = dateFormat(new Date(date), "dd-mm-yyyy");
            }
            return result;
        },
        async getFiles() {
            this.loading = true;
            this.files = await this.$API.getReadyFiles({});
            this.calculateFilteredResults()
            this.loading = false;
        },
        headerOptions(value) {
                let result = [...new Set(
                    this.files.filter(x => x[value])
                        .map(x => ({ name: x[value], value: x[value] })))]
                let uniqueValues = [...new Set(result.map(x => x.value))]
                result = uniqueValues.map(x => (result.find(y => y.value === x)))
                if (value == 'status') {
                    result.push({ name: 'Unallocated', value: null })
                }
                return result

        },
        resetFilters(){
            this.filters = {}
            let keys = this.headers.map(x => x.value)
            keys.forEach(x => {
                this.filters[x] = []
            })
            this.calculateFilteredResults()
        },
        selectFile(item){
          this.item.shipmentFile = item
          this.item.shipmentFileId = item.id
          this.selectExistingFile = false
          this.containerStep = 2
        },
        updateData(data){
            let findIndex = this.files.findIndex(x=>x.id == this.selectedFile.id)
            let findFilteredIndex = this.filteredResults.findIndex(x=>x.id == this.selectedFile.id)

            if(findIndex>-1){
                this.files[findIndex].status = 'Ready'
            }

            if(findFilteredIndex>-1){
                this.filteredResults[findIndex].status = 'Ready'
            }
        },
        viewShipmentFile(file) {
            this.selectedFile = file
            this.previewKey++
            this.previewShipmentFile = true
        }
  },
};
</script>